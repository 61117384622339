

const EntrepreneurForm = ({handleChange, data}) => {
    return (
        <>
            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Nom et prénom  : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="fullname"
            placeholder="Raymon Gemuse"
            value={data["fullname"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Adresse : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="address_individual"
            placeholder="12 rue Fréderic Petit"
            value={data["address_individual"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Code Postal et ville : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="zip_code_individual"
            placeholder="80000 Amiens"
            value={data["zip_code_individual"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Numéro de téléphone : </label>
            <br />
            <input 
            type="phone" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="phone_individual"
            placeholder="01-23-45-67-89"
            value={data["phone_individual"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Adresse mail : </label>
            <br />
            <input 
            type="mail" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="mail_individual"
            placeholder="exemple@gmail.com"
            value={data["mail_individual"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Lieu d’immatriculation et numéro SIREN : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="siren_individual"
            placeholder="ex : Paris 1234567"
            value={data["siren_individual"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />
        </>
    );
};

export default EntrepreneurForm;