import React from 'react';

const StepperControl = ({handleClick , currentStep, steps}) => {
    return (
        <div className='container flex justify-around mt-4 mb-8'>

        <button 
        onClick={()=> handleClick()}
        type="button"
        className={`bg-white text-slate-400 uppercase py-2 px-4 rounded-xl 
        cursor-pointer font-semibold border-2 border-slate-300 
        hover:bg-slate-700 transition duration-200 ease-in-out 
        ${currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
        >
            Précédent
        </button>

        <a href="#title">
            <button 
            type={currentStep === steps.length ? "submit" : "button"}
            onClick={()=> handleClick("next")}
            className={`bg-orange 
            cursor-pointer text-white uppercase py-2 px-4 rounded-xl font-semibold border-2 border-slate-300 hover:bg-slate-700 transition duration-200 ease-in-out  ${currentStep === steps.length ? "hidden" : ""}`}
            >
                {currentStep  === steps.length - 1  ? "Confirmer" : "Suivant"}
            </button>
        </a>
        
            
        </div>
    );
};

export default StepperControl;