import { useEffect, useState } from 'react';
import axios from 'axios'
import './App.css';
import Policy from './Components/Policy';
import Stepper from './Components/Stepper';
import StepperControl from './Components/StepperControl';
import Company from './Components/steps/Company';
import Cookies from './Components/steps/Cookies';
import Data from './Components/steps/Data';
import Final from './Components/steps/Final';
import Host from './Components/steps/Host';
import { StepperContext } from './contexts/StepperContext';



function App() {

  const [currentStep, setCurrentStep] = useState(1);
  const [companyData, setCompanyData] = useState({
    cookies : {
      meta : false,
      google : false,
      x : false 
    },
    data : {
      fullname : false,
       mail : false,
      address : false, 
      phone : false, 
      birth : false,
    }
  });
  const [formData, setFormData] = useState([]);

  const steps = [ 
    "Information générale",
    "Information hébergeur",
    "Cookies récoltés",
    "Data collectées", 
    "Fin"
  ]

  const displayStep = (step) => {
      switch(step){
        case 1:
          return <Company />
        case 2: 
        return <Host />
        case 3: 
        return <Cookies/>
        case 4: 
        return <Data/>
        case 5: 
          return <Final/>
        default :
      }
  }

  const handleClick = (direction, e) => {
    let newStep = currentStep;
    direction === "next" ? newStep++ : newStep --;
    // Check 
    newStep > 0 && newStep <= steps.length  && setCurrentStep(newStep)
  }

  useEffect(() => {
    // // WITH NO ASYNC /AWAIT 
    // axios.get(`${process.env.REACT_APP_API}`)
    // .then((res) => {
    //   console.log(res)
    // })
    // .catch((error) => {
    //   console.log(error)
    // });

    // WITH ASYNC/AWAIT
    const fetchCompanies = async () => { 
      try {
        const res = await axios.get(`${process.env.REACT_APP_API} ` || `${process.env.REACT_APP_API_KEY}`);
        console.log(res.data);
      } catch (error) {
        console.log(error);
      };
    };
    fetchCompanies();

  }, []);

  const handleSubmmit = (e) => {
    e.preventDefault();
    
      const addInfo = async () => {
        try {
          const res = await axios.post(`${process.env.REACT_APP_API}/company` || `${process.env.REACT_APP_API_KEY}/company`, companyData)
          console.log(res)
        } catch (error) {
          console.log(error)
        };
      } ;
      addInfo();

  } ;




  return (
    
    <StepperContext.Provider value={{
      companyData,
      setCompanyData,
      formData,
      setFormData
    }}>
      <div className='max-w-screen-lg mx-auto shadow-xl rounded-2xl pb-2 bg-white'>
      
        <h1 className='h1-tittle p-3 text-3xl font-bold' id="title"> Politique de confidentialité </h1>
        <form 
        method='post'
        onSubmit={handleSubmmit} 
        >
    {/* STEPER */}
        <div className='container horizontal mt-5'>
          <Stepper 
            steps={steps}
            currentStep={currentStep}
          />
          {/* Display Components */}
          <div className='my-10 p-10'>
              {displayStep(currentStep)}
          </div>

        </div>

    {/* Navigation Control */}
        <StepperControl 
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
        </form>


      {/* Policy text */}
      <div className='max-w-screen-lg mx-auto shadow-xl mt-2 pb-2 bg-white'>
        <Policy  companyData={companyData}/> 
      </div>

    </div>  
    </StepperContext.Provider>
    
  );
}

export default App;
