

const CompanyForm = ({handleChange, data, message}) => {

  const statusList = ["EURL", "SA", "SARL", "SAS", "SASU", "SNC", "SCOP", "SCA",  "SCS"]

    return (
        <>
           <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Nom de la société  : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="name"
            placeholder="Vivres Bio"
            value={data["name"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Forme de la société : </label>
            <select 
            name="status"
            value={data["status"] || "" }
            onChange={handleChange}> 
              {statusList.map((status, index) => 
                 <option 
                 className="p-1 px-2 outline-none border border-gray-500 rounded-md"
                 key={index} 
                 value={status}>
                 {status}
                 </option> 
              )}
            </select>
            <br />
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Capital en €: </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md"
            name="capital"
            placeholder="1000"
            value={data["capital"] || "" }
            onChange={handleChange}
            />
            <p className="error-message">{message}</p>
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Lieu d’immatriculation et numéro SIREN : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            value={data["siren"] || ""}
            placeholder="(ex : Paris 123456789)"
            name="siren"
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Adresse du siège social :</label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            value={data["address"] || ""}
            placeholder="120 rue du Mont Cénis"
            name="address"
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Code Postal et ville :</label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="zip_code"
            placeholder="75018 Paris"
            value={data["zip_code"] || ""}
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Numéro de téléphone :</label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md"
            value={data["phone"] || ""}
            placeholder="01-23-45-67-89"
            name="phone"
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Adresse mail :</label>
            <br />
            <input 
            type="mail" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="mail"
            placeholder="vivresbio@gmail.com"
            value={data["mail"] || ""}
            onChange={handleChange}
            />
            <p className="error-message">{message}</p>
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Nom et prénom du représentant : </label>
            <br />
            <input 
            type="name" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="representative_fullname"
            placeholder="Raymond Gemuse"
            value={data["representative_fullname"] || ""}
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Fonction du représentant : </label>
            <br />
            <input 
            type="name" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md"
            name="representative_function"
            placeholder="Gérant"
            value={data["representative_function"] || ""}
            onChange={handleChange}
            />
            <br /> 
            <br />
           
        </>
    );
};

export default CompanyForm;