   //  eslint-disable-next-line
import React, { useContext, useState ,useEffect } from "react";
import { StepperContext } from "../../contexts/StepperContext";

const Data = () => {
   

   const { companyData, setCompanyData } = useContext(StepperContext);
 


    // PREVIOUS CODE BEFORE UPDATE

//    const [selectedData, setSelectedData] = useState([]);
//    const handleChange = (e) => {
//        const { name, checked } = e.target;
//        if (checked) {
//            setSelectedData((prev) => [...prev, name]);
//        } else {
//            setSelectedData((prev) => prev.filter(data => data !== name));
//        }
//    };
//    useEffect(() => {
//        const updatedData = selectedData.map((name) => {
//            switch (name) {
//                case "fullname":
//                    return "Nom et prénom";
//                case "mail":
//                    return "Adresse mail";
//                case "address":
//                    return "Adresse";
//                case "phone" : 
//                    return "Numéro de téléphone fixe ou portable";
//                case "birth" :
//                    return "Date de naissance"
//                default:
//                    return "";
//            }
//        },);

//        console.log('yes')
//        setCompanyData((prev) =>( {
//            ...prev,
//            data : updatedData 
//        }));
//    }, [selectedData, setCompanyData]);

  
    //  NEW CODE VERSION 

    const handleChange = (e) => {
        const { name, checked } = e.target;
       
        setCompanyData((prev) => ({
            ...prev, 
            data : {
                ...prev.data,
                [name] :checked
            }
        }));

 
    };
 
    // useEffect(() => {
    //     const updatedData = companyData.data.map((name) => {
    //         switch (name) {
    //             case "fullname":
    //                 return "Nom et prénom";
    //             case "mail":
    //                 return "Adresse mail";
    //             case "address":
    //                 return "Adresse";
    //             case "phone" : 
    //                 return "Numéro de téléphone fixe ou portable";
    //             case "birth" :
    //                 return "Date de naissance"
    //             default:
    //                 return "";
    //         }
    //     },);

    //     console.log('yes')

    //     setCompanyData((prev) =>( {
    //         ...prev,
    //         data : updatedData 
    //     }));

    // }, [setCompanyData]);

    return (
        <div>
             <h5> Cochez les informations personnelles collectées par votre site web : </h5>
            <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                Nom et prénom :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="fullname"
                    checked={companyData.data.fullname}
                    onChange={handleChange}
                />
            </label>
            <br /><br />

            <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                Adresse mail :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="mail"
                    checked={companyData.data.mail}
                    onChange={handleChange}
                />
            </label>

            <br /><br />

            <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                Adresse :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="address"
                    checked={companyData.data.address}
                    onChange={handleChange}
                />
            </label>

            <br /> <br />

            <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                Numéro de téléphone fixe ou portable : 
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="phone"
                    checked={companyData.data.phone}
                    onChange={handleChange}
                />
            </label>

            <br /><br />

            <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                Date de naissance :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="birth"
                    checked={companyData.data.birth}
                    onChange={handleChange}
                />
            </label>
        </div>
    );
};

export default Data;
