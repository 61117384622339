import { useContext } from "react";
import { StepperContext } from "../../contexts/StepperContext";


const Host = () => {
    const {companyData, setCompanyData} = useContext(StepperContext);

    const handleChange = (e) => {
        const {name, value} = e.target
        setCompanyData({...companyData, [name] : value})
    };

    return (
        <div>
            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Nom de l'hébergeur : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="host"
            placeholder="OVH"
            value={companyData["host"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Siége social de l'hébergeur: </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="host_address"
            placeholder="2 rue Kellermann "
            value={companyData["host_address"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Code postal et ville  : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="host_zip"
            placeholder="59100 Roubaix"
            value={companyData["host_zip"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

        </div>
    );
};

export default Host;