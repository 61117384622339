

const Final = () => {
    return (
        <div>
            <h3 className="h3-tittle p-5 text-center"> Bravo !!</h3>
            <p>Nous tenons à vous féliciter pour votre implication dans la création de votre politique de confidentialité personnalisée. Elle est désormais disponible en copiant collant le texte ci-dessous.</p>
        
            
        </div>
    );
};

export default Final;