import React, { useContext } from 'react';
import { StepperContext } from '../../contexts/StepperContext';

const Cookies = () => {
    const { companyData, setCompanyData } = useContext(StepperContext);

    const handleChange = (e) => {
        const { name, checked, value } = e.target;

        if (name === "additional_cookies") {
            setCompanyData((prev) => ({
                ...prev,
                additional_cookies : value,
            }));
        } else {
            setCompanyData((prev) => ({
                ...prev,
                cookies: {
                    ...prev.cookies,
                    [name]: checked,
                },
            }));
        }
        
    };

    const handleAdditionalInputChange = (e) => {
        // setShowAdditionalInput(e.target.checked);
        const {name, checked} = e.target 
        setCompanyData((prev) => ({
            ...prev,
            [name] : checked
        }))
        if (!companyData.display_input){
            setCompanyData((prev) => ({
                ...prev,
                additional_cookies : ""
            }))
        }
    };  


    return (
        <div>
        <h5> Cochez les cookies collectés par votre site web : </h5>
            <label className="font-bold mt-6 text-slate-600 text-xs uppercase"> Cookies Meta :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="meta"
                    checked={companyData.cookies.meta}
                    onChange={handleChange}
                />
            </label>

            <br />
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs uppercase"> Cookies Google :
                <input
                    type="checkbox"
                    className="p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="google"
                    checked={companyData.cookies.google}
                    onChange={handleChange}
                />
            </label>

            <br />
            <br />
            <label className="font-bold mt-6 text-slate-600 text-xs uppercase"> Cookies X :
                <input
                    type="checkbox"
                    className="p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name="x"
                    checked={companyData.cookies.x}
                    onChange={handleChange}
                />
            </label>
            <br /><br />
              {/* Case à cocher pour afficher le champ de saisie */}
              <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
              Ajouter d'autres cookies :
                <input
                    type="checkbox"
                    className="mt-6 p-1 px-2 outline-none border border-gray-500 rounded-md"
                    name = "display_input"
                    onChange={handleAdditionalInputChange}
                    checked={companyData.display_input}
                />
            </label>

            <br />
            <br />

            {companyData.display_input && (
                <>
                <label className="font-bold mt-6 text-slate-600 text-xs uppercase">
                    Indiquer le nom des cookies et ce qu'ils font:
                </label>
                <p>Nommez tous les cookies et expliquez brièvement à quoi ils servent (leurs finalités). </p>
                <textarea
                type="text"
                name="additional_cookies"
                cols="50"
                rows="5"
                placeholder='ex : Cookies de session permettant la gestion des utilisateurs.'
                value={companyData.additional_cookies || ""}
                className="p-1 px-2 outline-none border border-gray-500 rounded-md"
                onChange={handleChange}
                />
                </>
            )}

        </div>
    );
};

export default Cookies;
