

const AssociationForm = ({handleChange, data}) => {
    return (
        <div>
            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Nom de l'association  : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            placeholder="ex : UNICEF"
            name="name_asso"
            value={data["name_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">N° de déclaration préfectorale (RNA) :
             </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md"
            name="declaration_asso"
            placeholder="W123456789"
            value={data["declaration_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Lieu de la déclaration : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="city_asso"
            placeholder="Préfecture Somme"
            value={data["city_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Siége social de l'association : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            placeholder="12 Rue Frédéric Petit,"
            name="address_asso"
            value={data["address_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Code postal et ville : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="zip_code_asso"
            placeholder="80000 Amiens"
            value={data["zip_code_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Numéro de téléphone: </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="phone_asso"
            placeholder="01-00-00-00-00"
            value={data["phone_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />

            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Adresse mail : </label>
            <br />
            <input 
            type="mail" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="mail_asso"
            placeholder="exemple@monassociation.fr"
            value={data["mail_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Prénom et nom du représentant de l'association : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="representative_asso"
            placeholder="Raymond Gemuse"
            value={data["representative_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />


            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase"> Fonction du représentant de l'association : </label>
            <br />
            <input 
            type="text" 
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="function_asso"
            placeholder="Président"
            value={data["function_asso"] || "" }
            onChange={handleChange}
            />
            <br /> 
            <br />
        </div>
    );
};

export default AssociationForm;