import { useContext, useState } from "react";
import { StepperContext } from "../../contexts/StepperContext";
import AssociationForm from "./conditionning/AssociationForm";
import CompanyForm from "./conditionning/CompanyForm";
import EntrepreneurForm from "./conditionning/EntrepreneurForm";


const Company = () => {
    const {companyData, setCompanyData} = useContext(StepperContext);
    const [message, setMessage] = useState("")
    
    const handleCompanyChange = (e) => {
        const {name, value} = e.target

        const verifmail = (email) => {
            console.log(email)
           return email.toLowerCase().match(/^[+]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$/);
        }

        switch (true){
            case (name === 'capital' && isNaN(value)):
                return setMessage("Veuillez saisir des nombres");
            case (name === 'mail' && verifmail(value)):
                return setMessage("E-mail incorrect")
            default : setMessage("")
        }
        setCompanyData({...companyData, [name] :value });
    };

    // Change form depending value
    const handleFormChange = (e) => {
        const {value} = e.target;
        setCompanyData({...companyData, situation : value})
    };

    // Display form depending situation 
    const displayForm = (name) => {
        switch(name){
            case 'individual': 
                return <EntrepreneurForm handleChange={handleCompanyChange} data={companyData}/>;
            case 'entreprise' :
                return <CompanyForm handleChange={handleCompanyChange} data={companyData} message={message}/>;
            case 'association':
                return <AssociationForm  handleChange={handleCompanyChange} data={companyData}/>
            default :
        }
        
    }

 

    return (
        <div className="w-full mx-2 flex-1">
            <label className="font-bold mt-6 text-slate-600 text-xs  uppercase">Nom du site web </label>
            <br />
            <input 
            type="text" 
            placeholder="https://politique-de-confidentialite.consuetude.fr/"
            className="p-1 px-2 outline-none border border-gray-500 rounded-md large-input"
            name="website"
            value={companyData["website"] || ""}
            onChange={handleCompanyChange}
            />
            <br />

            {/* SITUATION */}
            <p className="font-semibold mt-6 text-slate-600 text-xl  uppercase"> Situation  : </p>

            <label className=" mt-6 pr-4 text-slate-600 text-xs  uppercase">
            Entreprise individuelle
            </label>
            <input 
            type="radio" 
            className="p-1 px-2 outline-none border border-gray-500 "
            name="situation"
            value="individual"
            checked={companyData.situation === "individual"}
            onChange={handleFormChange}
            />

            <br />

            <label className=" mt-6 pr-4 text-slate-600 text-xs  uppercase">
            Entreprise
            </label>
            <input 
            type="radio" 
            className="p-1 px-2 outline-none border border-gray-500 "
            name="situation"
            value="entreprise"
            checked={companyData.situation === "entreprise"}
            onChange={handleFormChange}
            />
            <br />

            <label className=" mt-6 pr-4 text-slate-600 text-xs  uppercase">
            Association
            </label>
            <input 
            type="radio" 
            className="p-1 px-2 outline-none border border-gray-500 "
            name="situation"
            value="association"
            checked={companyData.situation === "association"}
            onChange={handleFormChange}
            />

            <br />
            <br />

            {displayForm(companyData.situation)}
            
        </div>
    );
};

export default Company;