import { useState, useEffect } from "react";
import {CopyCheck, Copy} from "lucide-react"

const Policy = ({companyData}) => {
    
    const [association , setAssociation] = useState(false);
    const [entreprise, setEntreprise] = useState(false);
    const [individual, setIndividual] = useState(false);

    const [copySuccess, setCopySuccess] = useState(false)

    const [hasGoogle, setHasGoogle] = useState(false);
    const [hasMeta, setHasMeta] = useState(false);
    const [hasX, setHasX] = useState(false);


    const updateCookies = (cookiesObject) => {
        setHasGoogle(cookiesObject.google ?? false);
        setHasMeta(cookiesObject.meta ?? false);
        setHasX(cookiesObject.x ?? false);     
    };
    
    // Function pour mettre à jour l'affichage des datas
   
    const updatedData = (dataObject) => {
        const displayData = [];
      
        if (dataObject.fullname) {
          displayData.push("Nom et prénom");
        }
      
        if (dataObject.mail) {
          displayData.push("Adresse mail");
        }
      
        if (dataObject.address) {
          displayData.push("Adresse postale");
        }

        if (dataObject.phone) {
            displayData.push("Numéro de téléphone portable ou fixe");
          }
        
        if (dataObject.birth) {
            displayData.push("Date de naissance");
          }
      
        return (
          <ul>
            {displayData.map((el, i) => (
              <li key={i}>{el}</li>
            ))}
          </ul>
        );
      };

   // Fonction pour mettre à jour les états en fonction de la situation
  const updateStates = (situation) => {
    setAssociation(situation === 'association');
    setEntreprise(situation === 'entreprise');
    setIndividual(situation === 'individual');
  };


  useEffect(() => {

    updateCookies(companyData.cookies);   

    // Utilisez la fonction pour mettre à jour les états en fonction de companyData.situation
    updateStates(companyData.situation);
  }, [companyData.situation, companyData.cookies, companyData.data]);
    
  const formatNumber = (number) => {
    const  format = parseInt(number)
    if (!format){
        return 0; 
    }
    return format
  };

  const handleCopyPaste = () => {
    const copyText = document.getElementById("copyPaste").innerText;

    // Create a textarea element to use the Clipboard API
    const textarea = document.createElement("textarea");
    textarea.value = copyText;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the textarea
    document.execCommand('copy');

    // Remove the textarea from the DOM
    document.body.removeChild(textarea);
    setCopySuccess(true)
  };

    return (
        <>  
          
            <div className="copy-paste">
                {copySuccess 
                ? 
                    <>
                    <CopyCheck onClick={handleCopyPaste}/>
                    <span>Texte copié</span>
                    
                    </>
                : 
                    <>  
                    <Copy onClick={handleCopyPaste}/>
                    <span>Copier le texte</span>
                    </>
                }
            </div>
            
            <section id="copyPaste">

            
               
                <h3 className="h3-tittle">
                    politique de confidentialité du site {companyData.website}
                </h3>
                    
                
                  
                <article>
                    <h4>
                    1. PRÉAMBULE ET DESCRIPTION
                    </h4>
                    {/* RENDU POOUR UNE ENTREPRISE */}
                    {entreprise && (
                        <p> La présente politique de confidentialité (ci-après la « Politique de Confidentialité ») a pour objet de décrire les modalités de collecte et de traitement de vos Données Personnelles par l'<span>{companyData.situation}</span> <span >{companyData.name}</span>, 
                        <span > {companyData.status}</span>, au capital de <span >{formatNumber(companyData.capital)}</span> euros, dont le siège social est situé au 
                        <span >{companyData.address} {companyData.zip_code}
                        </span>, immatriculée au Registre du Commerce et des Sociétés de 
                      <span>{companyData.siren} </span>, représentée par son
                      <span >{companyData.representative_function} {companyData.representative_fullname} </span> 
                       (ci-après désignée « <span >{companyData.name}</span>»).
                    </p>
                    )}
                    {/* RENDU POOUR UNE ASSO */}
                    {association && (
                        <p> La présente politique de confidentialité (ci-après la « Politique de Confidentialité ») a pour objet de décrire les modalités de collecte et de traitement de vos Données Personnelles par l'<span >{companyData.situation} </span> <span> {companyData.name_asso} </span>, dont le siège social est situé au <span >{companyData.address_asso} </span> 
                        , déclaréé auprès de la  préfecture <span >{companyData.city_asso}  </span> sous le numéro 
                        <span > {companyData.declaration_asso} </span> , représentée <span > {companyData.representative_asso} </span>
                         (ci-après désignée « <span >{companyData.name_asso}</span> » et/ou « Nous »).
                    </p>
                    )}

                    {/* RENDU POOUR UNE ENTREPRISE INDIVIDUELLE */}
                    {individual && (
                        <p> La présente politique de confidentialité (ci-après la « Politique de Confidentialité ») a pour objet de décrire les modalités de collecte et de traitement de vos Données Personnelles par l' <span>{companyData.situation}</span>
                        <span>{companyData.fullname}</span> , dont le siège social est situé au 
                        <span>{companyData.address_individual}</span>, <span>{companyData.zip_code_individual}</span> , immatriculée au Registre du Commerce et des Sociétés de <span>{companyData.siren_individual}</span>, représentée (ci-après désignée « <span> {companyData.fullname}</span> »).
                    </p>
                    )}

                    <p>
                        Les termes de cette Politique de Confidentialité s’appliquent au Site Internet accessible à l’adresse URL suivante : <span><span>{companyData.website}</span></span>  (ci-après le « Site Internet » et défini à l’article 2).
                    </p>

                    <p>
                    Vous pouvez nous  contacter à tout moment via la rubrique « Contact » de notre site internet <span><span>{companyData.website}</span></span> .</p>

                    <p>
                    <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span>
                     agit en tant que Responsable de Traitement au sens du Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données.
                    </p>


                    <p>
                        Cette Politique de Confidentialité peut faire l’objet de mises à jour en fonction des évolutions de la réglementation  applicable et/ou des traitements de Données Personnelles que Nous réalisons. Tout changement sera indiqué par un lien vers la Politique de Confidentialité, intégré au Site Internet. En cas de modifications importantes de cette Politique, Vous devrez manifester votre consentement clair concernant ces changements.
                    </p>
                </article>

                <article>
                    <h4 >
                        2. DÉFINITIONS
                    </h4>

                    <p>
                        « Cookies » : désigne les traceurs utilisés par le Site Internet afin de faciliter la navigation de l’Utilisateur, de proposer des services adaptés aux domaines d’intérêt de l'Utilisateur (cookies publicitaires), de permettre le développement de statistiques (cookies de performance) et de permettre aux Utilisateurs d’accéder à des produits et services personnalisés sur le Site Internet.

                    </p>

                    <p>
                        « Données Personnelles » ou « Données à caractère personnel » : désigne toute information se rapportant à une personne physique identifiée ou identifiable ; est réputée être une « personne physique identifiable » une « personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu’un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale » conformément à la définition du Règlement (UE) n°2016/679 du 27 avril 2016 (« RGPD ») ;

                    </p>


                    <p>
                        « Lois Applicables ou lois en vigueur » : les lois, les mesures, directives, règlements édictés ou promulgués par une entité gouvernementale (ceci incluant tout pouvoir décisionnaire national, étranger, supranational, étatique, régional, communal ou autre décision ou orientation prise par une autorité de contrôle européenne ou autre, y compris, le cas échéant, les décisions et orientations de la Commission européenne, plus précisément la Directive 95/46/CE, directive 2002/58/CE) telles que transposées dans la législation nationale de chaque État membre (la loi n° 78-17 du 6 janvier 1978 « Informatique et Libertés » régulièrement modifiée, dont les nouvelles dispositions sont applicables depuis le 1er juin 2019) ainsi que leur modification ou remplacement postérieur à leur première application y compris par le RGPD et les mesures complétant le RGPD. Ceci couvre également tous les principes, mesures ou lignes directrices d’autorégulation propres à l’industrie qui sont applicables dans le lieu ou la région où les services sont fournis ou reçus, liés au traitement des Données à caractère personnel ou à l’interception, l’enregistrement ou la surveillance des communications ;
                    </p>

                    <p>
                        « Responsable de Traitement » : désigne la personne physique ou morale, l’autorité publique, le service ou tout autre organisme qui, seul ou conjointement avec d’autres, détermine les finalités et les moyens du traitement des Données à caractère personnel. Il s’agit ici de <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> s’agissant des Données Personnelles collectées depuis le Site Internet <span><span>{companyData.website}</span></span>;
                    </p>

                    <p>
                        « RGPD » ou le « Règlement Général sur la Protection des Données » : le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des Données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE ;
                    </p>

                    <p>
                        « Site Internet » :  désigne le site internet édité par <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> et consultable à l’adresse URL suivante <span>{companyData.website}</span>, est hébergé par la société <span>{companyData.host}</span>  située <span>{companyData.host_address}</span> <span>{companyData.host_zip}</span>.
                    </p>

                    <p>
                        « Sous-Traitant » : désigne au sens du RGPD, la personne physique ou morale (entreprise ou organisme public) qui traite des Données Personnelles pour le compte d’un autre organisme (le Responsable de traitement), dans le cadre d’un service ou d’une prestation. Il s’agit ici notamment de la société <span>{companyData.host}</span> (hébergeur du Site Internet).
                    </p>

                    <p>
                        « Utilisateur (s) » et/ou « Vous » : désigne tout visiteur du Site Internet et tout Utilisateur qui a entré des Données Personnelles sur le Site Internet afin d’accéder à tout ou partie des rubriques telles que proposées sur le Site Internet, et qui a accepté la présente Politique de Confidentialité.
                    </p>
                </article>

                

                <article>
                    <h4 >
                        3. QUELLES INFORMATIONS <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> RECUEILLE-T-ELLE À VOTRE SUJET ?
                    </h4>

                    <p>
                        3.1 	Nous recueillons les Données Personnelles que Vous Nous fournissez lorsque :
                    </p>

                    <ul>
                        <li>Vous Nous contactez pour obtenir des informations sur nos services ;</li>
                        <li>Vous nous adressez un message via la rubrique « Contact ».</li>
                    </ul>

                    <p>3.2 Les Données Personnelles que Nous recueillons directement auprès de Vous comprennent notamment :</p>

                    {updatedData(companyData.data)}

                    <p>3.4 De manière générale, l’ensemble des formulaires disponibles sur le Site Internet lors de la collecte de vos Données Personnelles, précise si la collecte de Données est obligatoire ou non.</p>

                    <p>Dans le cas où Vous ne fournissez pas ces Données alors qu’elles sont impératives, <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> ne sera pas en mesure de fournir les services correspondants.</p>

                    <p>3.5 Nous pouvons également collecter automatiquement des informations, telles que votre adresse IP, le type de navigateur, le temps d'accès, les informations de navigation ou toute autre information, par l’intermédiaire de notre système de suivi analytique, y compris les Cookies ou toute autre technologie équivalente (cf. infra article 7 sur les Cookies).</p>

                </article>

                <article>
                    <h4 >
                        4. FINALITÉS POUR LESQUELLES NOUS TRAITONS VOS DONNÉES PERSONNELLES
                    </h4>

                    <p>4.1 Nous pouvons traiter vos Données Personnelles aux fins décrites dans la présente Politique de Confidentialité, et précisément en vue de :</p>
                    <ul>
                        <li>Fournir toutes informations sur les activités de <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> présentées via le Site Internet, y compris, mais sans s’y limiter, via la rubrique « Contact » apparaissant sur la page d'accueil du Site Internet qui permet aux Utilisateurs de s’informer sur les activités proposées par {companyData.name} ; ce traitement est nécessaire pour effectuer, à votre demande, les démarches préalables à la contractualisation de vos relations avec <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> (article 6.1 b) du RGPD).</li>
                        <li>Vos Données seront ainsi exclusivement utilisées afin de pouvoir Vous proposer des prestations susceptibles de Vous intéresser ;</li>
                        <li>effectuer des opérations relatives à notre relation commerciale (le cas échéant) y compris la facturation, la comptabilité, l’exécution des prestations commandées ; ce traitement est nécessaire à l’exécution de la relation commerciale à laquelle la personne concernée (Vous) est partie (article 6.1 b) du RGPD);</li>
                        <li>communiquer avec Vous et Vous envoyer les documents ou informations que Vous pouvez Nous demander sur le Site Internet (messages via la rubrique « Contact », etc.) ; la base légale de ce traitement est le consentement (section 6.1 a) du RGPD).</li>
                        <li>Vos données seront ainsi exclusivement utilisées afin de Vous permettre de recevoir des informations en rapport avec votre demande ;</li>
                        <li>Surveiller et améliorer notre Site Internet, y compris les opérations internes, telles que la maintenance du Site Internet et des systèmes de sécurité ; ce traitement est basé sur notre intérêt légitime (section 6.1 f) du RGPD), notamment afin de Nous prémunir contre toute atteinte à la sécurité des réseaux et in fine des Données Personnelles ou, si nécessaire, sur votre consentement en matière de Cookies (cf. infra article 7).</li>
                    </ul>
                    <p>Aucune autre finalité n’est envisagée à ce jour. Toute nouvelle finalité fera l’objet d’une modification des présentes, et sera soumise à votre information préalable.</p>
                    <p>4.2 Nous pouvons également utiliser vos Données Personnelles pour tout traitement qui pourrait être nécessaire aux fins de respecter une obligation légale à laquelle Nous sommes soumis.</p>
                </article>

                <article>
                    <h4 >
                        5.PARTAGE ET TRANSFERT DE VOS DONNÉES PERSONNELLES
                    </h4>

                    <p>5.1 Nous pouvons divulguer vos Données Personnelles à des tiers, dans la mesure où cela est strictement nécessaire à l’administration de notre Site Internet, et plus généralement, à la pleine utilisation du Site Internet et des rubriques que Nous Vous proposons.</p>

                    <p>À cet égard, vos Données Personnelles peuvent être partagées avec notre personnel et sous-traitants autorisés. Tout partage de vos Données Personnelles avec nos partenaires commerciaux ou d’autres tiers sera soumis à votre consentement.</p>

                    <p>5.2 Nos sous-traitants sont impliqués dans les domaines suivants :</p>

                    <ul>
                        <li>L’hébergement, l’entretien du Site Internet et toutes autres opérations techniques y afférentes ;</li>
                        <li>La transmission d’informations/ de documentation relatives aux activités proposées via le Site Internet et toutes les opérations techniques et commerciales qui y sont liées.</li>
                    </ul>

                    <p>5.3 Vos Données Personnelles conservées dans notre base de données seront stockées sur les serveurs du fournisseur d’hébergement de notre Site Internet, à savoir la société <span>{companyData.host}</span> située <span>{companyData.host_address}</span> <span>{companyData.host_zip}</span>.</p>

                    <p>5.4 <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> s’engage à faire héberger et traiter vos Données à caractère personnel exclusivement sur le territoire d’un pays membre de l’Union européenne.</p>

                    <p>En cas de transfert de vos Données Personnelles par <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> en dehors de l’Union Européenne, Nous procéderons aux modifications nécessaires de la présente Politique de Confidentialité et encadrerons à cet effet ces transferts dans le respect du RGPD et plus généralement des Lois en vigueur.</p>

                    <p>5.5 En plus des divulgations spécifiques de Données Personnelles listées dans cet article, Nous pouvons divulguer vos Données Personnelles afin de Nous conformer à une obligation légale.</p>

                    <p>Nous pouvons également divulguer vos Données personnelles lorsque cette divulgation est nécessaire pour l’établissement, l’exercice ou la défense de <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> en cas de réclamations, que ce soit dans le cadre d’une procédure judiciaire ou dans le cadre d’une procédure administrative ou non judiciaire.</p>

                    <p>5.6 Dans le cas où Nous aurions l’intention de conclure une transaction qui modifierait la structure de notre entreprise (réorganisation, fusion, vente, coentreprise, cession, transfert, changement de contrôle ou toute autre cession de la totalité ou d’une autre partie de notre entreprise, de nos actifs ou de nos actions), Nous conservons la faculté de partager les Données Personnelles avec des tiers dans le but de faciliter et de conclure cette transaction.</p>

                </article>

                <article>
                    <h4 >
                        6. COMBIEN DE TEMPS <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> CONSERVE-T-ELLE VOS DONNÉES PERSONNELLES ?
                    </h4>

                    <p>6.1 Nous conservons vos Données Personnelles aussi longtemps que cela est nécessaire à la fourniture des informations relatives aux activités proposées sur le Site Internet et dans le cadre de l’exécution de notre relation commerciale (le cas échéant) ou à d’autres fins essentielles telles que : le respect de nos obligations légales, le règlement des différends et l’application de nos accords.</p>

                    <p>6.2 En cas de demande de suppression de vos Données Personnelles et/ou si Vous exercez votre droit de demander la suppression de vos Données Personnelles, Nous pouvons cependant les conserver sous forme d’archivage intermédiaire, pour la durée nécessaire au respect de nos obligations légales, comptables et fiscales.</p>

                    <p>Les périodes de conservation effective des Données Personnelles peuvent alors varier, compte tenu de nos obligations légales ou contractuelles, et du type de Données concernées, ce que les Utilisateurs reconnaissent et acceptent.</p>

                    <p>6.3 Il est précisé que :</p>

                    <ul>
                        <li>Les données de prospect (Utilisateurs qui Nous ont par exemple sollicité via la rubrique « Contact » de notre Site Internet sans avoir donné lieu à une relation commerciale avec {companyData.name}) peuvent être conservées pendant un délai maximum de trois (3) ans à compter de leur collecte ou du dernier contact émanant du prospect, sauf accord spécifique pour une durée plus longue ;</li>

                        <li>S’agissant des Cookies (cf. infra article 7), le consentement sollicité est valable pour une durée de six (6) mois à compter du premier dépôt de cookie sur l’appareil de l’Utilisateur. Une fois ce délai passé, les Cookies seront automatiquement supprimés et le consentement de l’Utilisateur sera de nouveau sollicité.</li>
                    </ul>
                </article>

                <article>
                    <h4 >
                        7. COOKIES
                    </h4>

                    <p>7.1 Lorsque Vous vous connectez à l’une des pages de notre Site Internet, aussi bien dans le cadre de la consultation puis de l’exploitation des Services que Nous proposons sur notre Site Internet, Nous sommes susceptibles, sous réserve de vos choix, d’être amenés à installer différents Cookies dans votre terminal Nous permettant de reconnaître le navigateur de votre terminal pendant la durée de validité du cookie concerné.</p>

                    <p>De manière générale, ces Cookies ont pour objectif d’améliorer le fonctionnement du Site Internet et votre expérience de navigation.</p>

                    <p>Les Cookies que Nous émettons sont utilisés aux fins décrites ci-dessous (article 7.3) qui résultent des paramètres que Vous aurez renseignés lors de votre visite et que Vous pouvez exprimer et modifier à tout moment dans les conditions rappelées au sein de la présente Politique.</p>

                    <p>7.2 Les opérations de lecture et d’écriture que permettent les Cookies n’impliquent pas systématiquement un traitement de Données à caractère personnel.</p>

                    <p>Autrement dit, les informations collectées ne permettent pas toujours de Vous identifier directement, mais elles peuvent Vous apporter une expérience Web plus personnalisée.</p>

                    <p>Il est toutefois des cas où un tel traitement est effectué, et fait partie intégrante d’un traitement de Données à caractère personnel soumis aux dispositions de la loi informatique et libertés du 6 janvier 1978 dûment modifiée, et du RGPD, dans les conditions de la présente Politique de Confidentialité.</p>

                    <p>7.3 S’agissant spécifiquement des Cookies utilisés par <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span>, ils sont répertoriés comme suit :</p>

                    

                    {hasGoogle && (
                        <>
                            <p className="cookie">Cookies Google :</p>
                            

                            <p>Notre site web (ou application) utilise des cookies fournis par Google pour diverses finalités, notamment l'analyse de l'audience, la personnalisation des publicités et l'amélioration de l'expérience de l'utilisateur. Les cookies Google peuvent collecter des informations telles que l'adresse IP, les préférences de navigation, les interactions avec le site web, et plus encore. Ces informations peuvent être partagées avec Google et utilisées conformément à leur politique de confidentialité.</p> 
                    </>
                    )}

                    {hasX && (
                        <>
                            <p className="cookie">Cookies X (anciennement Twitter) :</p>

                            <p> X utilise également des cookies de suivi pour collecter des données sur la manière dont les utilisateurs interagissent avec le site, les tweets consultés, les clics effectués, et d'autres informations liées à l'engagement de l'utilisateur. Ces données sont utilisées pour améliorer l'expérience de l'utilisateur et personnaliser les publicités.</p>
                        </>
                    )}

                    {hasMeta && (
                        <>
                            <p className="cookie">Cookies Meta : </p>

                            <p>Les cookies  Meta fournis par Facebook, sont utilisés pour l'amélioration de l'expérience de l'utilisateur, la personnalisation des publicités et l'analyse de l'audience.</p>
                        </>
                    )}
                    

                    {companyData.additional_cookies && (
                        <p> <span>{companyData.additional_cookies} </span>  </p> 
                    )}       
                 
                    
                    <p>Lorsque Vous utilisez ces boutons de partage, un cookie tiers du réseau social concerné est installé. Si Vous êtes connectés au réseau social lors de votre navigation sur notre Site Internet, les boutons de partage permettent de relier les contenus consultés à votre compte utilisateur. Si vous avez refusé la mise en œuvre de ces Cookies, Vous devrez vous authentifier lors de chaque opération de partage vers le(s) réseau(x) concerné(s).</p>
                    <p>Les données de navigation et les cookies publicitaires : il s’agit de pouvoir Vous proposer des publicités les plus pertinentes possibles, adaptées à vos centres d'intérêts. Le refus de ces cookies publicitaires n’a pas d’impact sur l’utilisation de notre Site Internet.</p>
                    <p>Cependant, le fait de les refuser est susceptible de ne pas entraîner l’arrêt de la publicité sur notre Site Internet, laquelle ne sera dès lors pas adaptée/ciblée en fonction de vos centres d’intérêts. Ces Cookies sont majoritairement des cookies tiers et dépendent principalement des régies publicitaires. Ils proviennent de sociétés telles que Google Adwords.</p>
                    <p>7.4 Lors de votre connexion sur notre Site Internet, une bannière s’affiche. Sous réserve de votre choix, des Cookies seront stockés dans la mémoire de votre ordinateur, smartphone, tablette, mobile etc. Les informations ainsi collectées peuvent être utilisées par le Site Internet ou par un tiers, telle qu’une régie publicitaire, ou tout autre partenaire.</p>
                    <p>Par cette bannière de consentement Vous pouvez décider, au regard des finalités d’usage liées aux Cookies préalablement détaillées (i) de tout accepter, (ii) de tout refuser ou (iii) de donner votre consentement pour chaque finalité (exemples : partage de contenus, analyse des centres d’intérêts aux fins de publicités personnalisées, réseaux sociaux etc…).</p>
                    <p>En cliquant sur les intitulés de ces finalités, un court descriptif apparaît et Vous pouvez ainsi modifier les paramètres par défaut.</p>
                    <p>Il Vous sera possible de retirer votre consentement à tout moment par un lien accessible en pied de page.</p>
                    <p>La liste des entités partenaires ayant recours aux Cookies est à ce jour la suivante : Google Analytics.</p>
                    <p>7.5 Il est précisé que si Vous supprimez tous vos Cookies, Vous serez à nouveau susceptibles de mettre à jour vos préférences pour notre Site Internet lors d’une nouvelle connexion.</p>

                </article>

                <article>
                    <h4 >
                        8. SÉCURITÉ
                    </h4>
                    <p>8.1 Nous prenons toutes les mesures appropriées et raisonnables de nature à protéger les Données Personnelles contre l’accès non autorisé, l’utilisation, la divulgation, la modification et la destruction.</p>

                    <p>Nous assurons ainsi la sécurité des Données Personnelles et mettons en œuvre les mesures techniques et organisationnelles nécessaires, en ce qui concerne la nature, la portée et le contexte de transmission des Données Personnelles, ainsi que s’agissant des risques présentés par leur traitement, afin de préserver leur sécurité et, en particulier, de prévenir tout accident ou destruction illégale, perte, modification, divulgation, intrusion ou accès non autorisé à ces Données.</p>
                </article>

                <article>
                    <h4 >
                        9.VOS DROITS
                    </h4>

                    <p>9.1 Les principaux droits dont Vous disposez en vertu de la législation en vigueur sur la protection des Données sont les suivants :</p>
                    <ul>
                        <li>le droit d’accès - Vous pouvez demander des copies de vos Données Personnelles dans un format structuré, communément utilisé et lisible;</li>
                        <li>droit de rectification - Vous pouvez Nous demander de corriger les Données Personnelles inexactes et/ou de les compléter ;</li>
                        <li>le droit à l'effacement - Vous pouvez Nous demander d’effacer vos Données Personnelles ;</li>
                        <li>le droit de restreindre le traitement - vous pouvez Nous demander de restreindre le traitement de vos Données Personnelles ;</li>
                        <li>le droit de s’opposer au traitement - Vous pouvez vous opposer au traitement de vos Données Personnelles ;</li>
                        <li>le droit à la portabilité des Données - Vous pouvez demander que Nous transférions vos Données Personnelles à une autre organisation ou à Vous ;</li>
                        <li>le droit de porter plainte auprès d’une autorité de surveillance - Vous pouvez vous plaindre de notre traitement de vos Données Personnelles auprès d’une telle autorité (en France, la CNIL: <a href="https://www.cnil.fr">www.cnil.fr</a>) ;</li>
                        <li>le droit de retirer votre consentement - pour le cas où la base juridique de notre traitement de vos Données Personnelles serait le consentement, Vous pouvez solliciter le retrait de ce consentement.</li>
                    </ul>
                    <p>9.2 Vous pouvez exercer l’un de ces droits en rapport avec vos Données Personnelles, en formulant une demande écrite aux coordonnées énoncées ci-dessous.</p>
                </article>
                <article>
                <h4 >
                10. INFORMATIONS DE CONTACT
                </h4>

                <p>
                Pour toute demande en lien avec la confidentialité de vos Données Personnelles et/ou l’exercice des droits visés à l’article 9 ci-dessus, Vous pouvez contacter <span>{(entreprise && companyData.name) || (association && companyData.name_asso) || (individual && companyData.fullname)}</span> à l’adresse email suivante : <span>{(entreprise && companyData.mail ) || (association && companyData.mail_asso) || (individual && companyData.mail_individual)}</span> ou par lettre recommandée avec accusé de réception à l’adresse postale suivante : 
                {/* Adress  */}
                <span>{(entreprise && companyData.address) || (association && companyData.address_asso ) || (individual && companyData.address_individual)}</span> 
                {/* Zip code and city */}
                <span> {(entreprise &&companyData.zip_code) || (association && companyData.zip_code_asso) || (individual && companyData.zip_code_individual)}</span>
                .
                </p>
                
                
             </article> 

            </section>

        </>
    );
};

export default Policy;